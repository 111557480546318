import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import config from "../../../settings/config";
import {toast} from "react-toastify";
import ConnectorInfo from "./ConnectorInfo";

const RegistrationStep55 = (props) => {
    const [connectors, setConnectors] = useState([]);
    const token = localStorage.getItem('s_token');

    const location = useLocation();
    const {chargeStation} = location.state || {};
    /*
    в item лежит
        address: "NanoPi - based"
        addressCs: "ws://10.10.254.37:8180/steve/websocket/CentralSystemService/"
        arch: "armv7l"
        chargePointId: "22F555559"
        firmwareVersion: null
        groupId: "q"
        heartbeat: "2024-10-09T10:49:01.084+00:00"
        id: 1210
        ip: null
        model: "CPmodel"
        nickName: "Test for mobile app"
        passedTest: null
        powerCp: 160
        sn: "22F555559"
        street: null
   в connectors лежит массив коннекторов для данной станции
        0 : {id: 3470, sn: '22F555559', connectorId: 3, type: 'CCS', errorCode: 'NoError', …}
        1 : {id: 3471, sn: '22F555559', connectorId: 4, type: 'GB/T', errorCode: 'NoError', …}
        2 : {id: 3469, sn: '22F555559', connectorId: 2, type: 'GBT2', errorCode: 'NoError', …}
        3 : {id: 3434, sn: '22F555559', connectorId: 1, type: 'Mode3_type2', errorCode: 'NoError', …}
        4 : {id: 3435, sn: '22F555559', connectorId: 0, type: 'station', errorCode: 'NoError', …}

    элемент масcива:
        connectorId: 3
        errorCode: "NoError"
        id: 3470
        info: "Нет ошибок"
        limitAmperage: null
        limitPower: null
        sn: "22F555559"
        status: "Unavailable"
        timestamp: "2024-10-09T11:24:59"
        type: "CCS"
        versionController: "null"

     */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/connectors?sn=${chargeStation.sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    toast.error('Response not ok', {
                        position: 'top-center',
                        autoClose: 3000,
                    });
                    return;
                }

                const result = await response.json();
                setConnectors(result);
            } catch (error) {
                toast.error('Response error: ' + error, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            }
        };

        fetchData();

        // Запуск интервала для опроса каждые 15 секунд
        const intervalId = setInterval(fetchData, 15000);
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);

    }, [chargeStation.sn, token]); // Добавление зависимостей

    useEffect(() => {
        console.log(chargeStation);
        console.log(connectors);
    }, [chargeStation, connectors]); // Обновление логов при изменении item и connectors


    return (
        <div className="container mt-4">
            {/* Здесь можно отобразить данные из connectors */}

            <div><p>На станции <b>{chargeStation.nickName}</b> есть {connectors.length - 1} коннекторов ;)</p></div>
            <div className="container mt-4">
                <h1>Connector List</h1>
                <div className="row">
                    {connectors.length > 0 ? (
                        connectors.map(connector => (
                            <div className="col-md-6 mb-4" key={connector.id}>
                                {/* Передаём объект коннектора в компонент */}
                                <ConnectorInfo {...connector} />
                            </div>
                        ))
                    ) : (
                        <p>Loading connectors...</p>
                    )}
                </div>
            </div>

        </div>
    );
};

export default RegistrationStep55;
