import React from 'react';
import {Button, Card, ListGroup} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

const ConnectorInfo = (props) => {
    const {
        connectorId,
        errorCode,
        info,
        status,
        timestamp,
        type,
        versionController,
        sn,
        limitAmperage,
        limitPower
    } = props;

    const navigate = useNavigate();

    // Функции для обработки кнопок
    const handleStart = () => {
        console.log(`Starting connector ${props.type}`);
        navigate('/manage', {state: {props}});
        // Логика для старта коннектора
    };

    /*const handleStop = (props) => {
        console.log(`Stopping connector ${connectorId}`);
        // Логика для остановки коннектора
    };*/

    // Скрываем из списка коннекторов саму станцию
    if (connectorId === 0) {
        return null;
    }

    return (
        <Card className="mb-1 shadow-sm">
            <Card.Header as="h5" className="bg-primary text-white">Connector: {type}</Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    {/* <ListGroup.Item><strong>Connector ID:</strong> {connectorId}</ListGroup.Item>*/}
                    <ListGroup.Item><strong>Status:</strong> {status}</ListGroup.Item>
                    {/*<ListGroup.Item><strong>Error Code:</strong> {errorCode}</ListGroup.Item>
                    <ListGroup.Item><strong>Info:</strong> {info}</ListGroup.Item>
                    <ListGroup.Item><strong>Type:</strong> {type}</ListGroup.Item>
                    <ListGroup.Item><strong>SN:</strong> {sn}</ListGroup.Item>
                    <ListGroup.Item><strong>Timestamp:</strong> {new Date(timestamp).toLocaleString()}</ListGroup.Item>
                    {limitAmperage && <ListGroup.Item><strong>Limit Amperage:</strong> {limitAmperage} A</ListGroup.Item>}
                    {limitPower && <ListGroup.Item><strong>Limit Power:</strong> {limitPower} kW</ListGroup.Item>}
                    <ListGroup.Item><strong>Controller Version:</strong> {versionController}</ListGroup.Item>*/}
                </ListGroup>
                {/* Блок кнопок */}
                <Button variant="success" onClick={handleStart} className="w-100 mt-3">Выбрать этот коннектор</Button>
            </Card.Body>
        </Card>
    );
};

export default ConnectorInfo;
