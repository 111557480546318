import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './StartScreen.module.css'
import {useNavigate} from "react-router-dom";

const StartScreen = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("pin") !== null) {
            navigate('/pin');
            // TODO Наличие токенов еще проверять
        } else {
            navigate('/step2');
        }
    }, []);

    return (
        <div className={styles.container}>

        </div>
    );
}

export default StartScreen;