var config = {};

/*config.twitter = {};
config.redis = {};*/
config.skuns = {};

config.app = {};

config.vk = {};

/*config.default_stuff =  ['red','green','blue','apple','yellow','orange','politics'];
config.twitter.user_name = process.env.TWITTER_USER || 'username';
config.twitter.password=  process.env.TWITTER_PASSWORD || 'password';
config.redis.uri = process.env.DUOSTACK_DB_REDIS;
config.redis.host = 'hostname';
config.redis.port = 6379;*/

/*config.skuns.ip = process.env.WEB_IP || '94.228.117.74';
config.skuns.port = process.env.WEB_PORT || '10023';*/

config.skuns.ip = process.env.WEB_IP || 'skuns.zs-pss.ru';
config.skuns.port = process.env.WEB_PORT || '10023';

config.app.host = 'https://api.factorycode.ru'; //*/
/*config.app.host = 'http://localhost:8080'; //*/
/*config.app.host = 'http://10.10.254.45:8080'; //*/

/*config.vk.clientId = process.env.VK_CLIENT_ID || 52225543; // my localhost*/
/*config.vk.clientId = process.env.VK_CLIENT_ID || 52402048; //my factorycode.ru*/
config.vk.clientId = process.env.VK_CLIENT_ID || 52353030; //nikita factorycode.ru*/

/*config.vk.redirectUrl = process.env.VK_REDIRECT_URL || 'http://localhost'; //my factorycode.ru*/
config.vk.redirectUrl = process.env.VK_REDIRECT_URL || 'https://app.factorycode.ru'; //my factorycode.ru */

module.exports = config;