import React, {useEffect, useState} from 'react';
import {Button, Card, Form, ListGroup} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import ConnectorStatus from "./ConnectorStaus";
import { Slider } from "primereact/slider";

const ManageConnector = () => {
    const location = useLocation();
    const {connectorId, sn, type, status} = location.state.props; // Получаем объект коннектора из propsкомпонент ползунок для react
    const config = require('../../../settings/config.js');
    const token = localStorage.getItem('s_token');

    const [connectorStatus, setConnectorStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(50);

    // Функция для опроса состояния коннектора каждые 15 секунд
    useEffect(() => {
        const fetchConnectorStatus = async () => {
            try {
                const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/last-transaction?sn=${sn}&type=${type}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setConnectorStatus(data);
            } catch (error) {
                toast.error(`Error fetching connector status: ${error}`, {autoClose: 3000});
            }
        };

        // Первоначальный запрос
        fetchConnectorStatus();

        // Запуск интервала для опроса каждые 15 секунд
        const intervalId = setInterval(fetchConnectorStatus, 15000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [sn]);

    // Функция для старта зарядки
    const handleStart = async () => {
        try {
            setIsLoading(true);
            /*const response = await fetch(`http://localhost/pss/api/v1/event/start?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'POST',
            });*/
            const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/start?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging started', {autoClose: 3000});
            } else {
                toast.error('Failed to start charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        } finally {
            setIsLoading(false);
        }
    };

    // Функция для остановки зарядки
    const handleStop = async () => {
        try {
            setIsLoading(true);
            /*const response = await fetch(`http://localhost/pss/api/v1/event/stop?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'POST',
            });*/
            const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/stop?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging stopped', {autoClose: 3000});
            } else {
                toast.error('Failed to stop charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        } finally {
            setIsLoading(false);
        }
    };

    const handleSliderChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Card className="mb-4 shadow-sm">
            <Card.Header as="h5" className="bg-primary text-white">Manage Connector {type}</Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>SN:</strong> {sn}</ListGroup.Item>
                    <ListGroup.Item><strong>Connector ID:</strong> {connectorId}</ListGroup.Item>
                    {/* <ListGroup.Item><strong>Current Status:</strong> {connectorStatus ? JSON.stringify(connectorStatus) : 'Loading...'}</ListGroup.Item>*/}
                    <ListGroup.Item>{/* Отображаем данные с помощью дочернего компонента, если они загружены */}
                        {connectorStatus ? (
                            <ConnectorStatus {...connectorStatus} sn={sn}/>
                        ) : (
                            <p>Loading...</p>
                        )}</ListGroup.Item>
                </ListGroup>

                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Ограничение </strong> {value} <strong>кВт</strong></ListGroup.Item>
                </ListGroup>

                <Form>
                    <Form.Group controlId="formSlider">
                        {/*<Form.Label>Set Value:</Form.Label>*/}
                        <Form.Control
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            onChange={handleSliderChange}
                            className="w-100 slider-no-border"
                        />
                    </Form.Group>
                </Form>

                {/* Блок кнопок */}
                <Button variant="success" onClick={handleStart} className="w-100 mt-3" disabled={isLoading}>Начать
                    зарядку</Button>
                <Button variant="danger" onClick={handleStop} className="w-100 mt-2" disabled={isLoading}>Остановить
                    зарядку</Button>
            </Card.Body>
        </Card>
    );
};

export default ManageConnector;
