import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer.module.css'
import {PinInput} from 'react-input-pin-code';
import {useLocation, useNavigate} from "react-router-dom";

const PinCodeOffer = () => {

    const [values, setValues] = useState(['', '', '', '']);
    const navigate = useNavigate();
    const {state} = useLocation();

    function handlePinComplite(values) {
        navigate('/pinoffer2', {state: {firstValues: values}})
    }

    return (
        <div className={styles.container}>
            <div className={styles.pinContainer}>
                <div className={styles.pinText}>Придумайте PIN-код:</div>
                <div>{state?.error ? 'Пин - коды не совпадают' : ''}</div>
                <PinInput
                    values={values}
                    onChange={(value, index, values) => setValues(values)}
                    //onChange={handlePinInput}
                    onComplete={handlePinComplite}
                    autoFocus
                    placeholder="😀"
                    size="lg"
                />
            </div>
        </div>
    );
}

export default PinCodeOffer;
