import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Header.module.css';

const Header = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <header className={styles.header}>
            {location.pathname !== '/' && (
                <button className={styles.backButton} onClick={() => navigate(-1)}>
                    Back
                </button>
            )}
            <h1 className={styles.title}>{title}</h1>
        </header>
    );
};

export default Header;
