import React, {useState} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import axios from 'axios';
import styles from './UserProfile.module.css';

const UserProfile = () => {

    const config = require('../../settings/config.js');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        birthday: '',
        carBrand: '',
        carModel: ''
    });

    // Получаем токен из localStorage
    const token = localStorage.getItem('m_token');

    // Конфигурация заголовков с Bearer токеном
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${config.app.host}/api/v1/user/profile`, formData, axiosConfig)
            .then(response => {
                console.log('Profile updated:', response.data);
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    return (
        <Container className={styles.profileContainer}>
            <Row className="justify-content-center">
                <Col xs={12} md={6}>
                    <h2 className="text-center mb-4">Профиль</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName" className="mb-3">
                            <Form.Label>Ваше имя:</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Введите ваше имя"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Введите ваш email"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formBirthday" className="mb-3">
                            <Form.Label>День рождения:</Form.Label>
                            <Form.Control
                                type="date"
                                name="birthday"
                                value={formData.birthday}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formCarBrand" className="mb-3">
                            <Form.Label>Марка автомобиля:</Form.Label>
                            <Form.Control
                                type="text"
                                name="carBrand"
                                value={formData.carBrand}
                                onChange={handleChange}
                                placeholder="Введите марку вашего автомобиля"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formCarModel" className="mb-3">
                            <Form.Label>Модель автомобиля:</Form.Label>
                            <Form.Control
                                type="text"
                                name="carModel"
                                value={formData.carModel}
                                onChange={handleChange}
                                placeholder="Введите моедль вашего автомобиля"
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Отправить
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default UserProfile;
