import React, {useEffect, useState} from 'react';
import styles from './RegistrationStep3.module.css'
import OtpInput from 'react-otp-input';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {isJwtExpired} from "jwt-check-expiration";
import {DeviceUUID} from "device-uuid";


const RegistrationStep3 = (props) => {

    const config = require('../../../settings/config.js');

        const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(59);

    const navigate = useNavigate();

    function handlePhoneChange() {
        navigate('/');
    }

    function handleOtpChange(otp) {
        setOtp(otp);
    }

    useEffect(() => {
        counter > 0 && setTimeout(function timer() {
            if (counter < 11) {
                setCounter((counter - 1).toString().padStart(2, '0'));
            } else {
                //
                setCounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    useEffect(() => {
        async function authHandle() {
            let uuid = new DeviceUUID().get();
            const authRequest = {
                username: localStorage.getItem('phone').replace(/\D/g, ''),
                password: otp,
                deviceId: uuid
            }

            try {
                const response = await fetch(`${config.app.host}/api/v1/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(authRequest),
                });

                if (!response.ok) {
                    toast.error('Неверный код ', {
                        position: "top-center",
                        autoClose: 3000
                    });
                    setOtp('');
                    // forceUpdate();
                }

                const token = await response.json();
                localStorage.setItem('m_token', token.token);
                localStorage.setItem('s_token', token.skunsToken);
                localStorage.setItem('groupId', token.groupId);
                console.log('isExpired is:', isJwtExpired(token.token));
                toast.success('Авторизация прошла успешно', {
                    position: "bottom-center",
                    autoClose: 3000
                });
                navigate('/pinoffer');

            } catch (error) {
                toast.error('Ошибка:' + error.message, {
                    position: "top-center-center",
                    autoClose: 3000
                });
            }
        }

        if (otp.length === 4) {
            authHandle();
        }

    }, [otp]);

    async function requestSMSRepeat() {
        if (counter != 0) {
            return;
        }
        try {
            const response = await fetch(`${config.app.host}/api/v1/getotp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: localStorage.getItem('phone').replace(/\D/g, '')}),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {

        }
        // End request SMS code
        toast.success('Вам отправлен SMS-код', {
            position: "bottom-center",
            autoClose: 3000
        });

        setCounter(59);
    }


        return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.titleText}>Введите код</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "Page title"*/}

                {/*start row block -- "An SMS will be sent to your number" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>На ваш номер поступит СМС с 4-х<br/>
                        значным кодом
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "An SMS will be sent to your number"*/}

                {/*start row block - "Phone number and change it"*/}
                <div className={styles.row2}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.phoneNumber}>
                        <span>{localStorage.getItem('phone')}</span>
                        <span
                            className={styles.changePhoneNumber}
                            onClick={handlePhoneChange}
                        >Изменить</span>
                    </div>
                </div>
                <div className={styles.column3}>{/*r2c3*/}</div>
                {/*end row block- "Phone number and change it"*/}

                {/*start row block -- get SMS button*/}
                <div className={styles.row3}>{/*r3c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.otpDiv}>
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input
                                {...props}
                                className={styles.otpInput}
                                type="number"
                                pattern="\d*"
                                inputmode="decimal"
                            />}
                            containerStyle={styles.otpInlineInput}
                        />
                    </div>


                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row4}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <span>00:{counter} Сообщение не пришло?</span>
                    <span
                        className={(counter == 0) ? styles.changePhoneNumber : styles.changePhoneNumberDisabled}
                        onClick={requestSMSRepeat}
                    >Повторить</span>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row5}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row6}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        {/*new line*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
            </div>
        );
    }
;

export default RegistrationStep3;
