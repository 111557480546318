import React, {useEffect, useRef, useState} from 'react';
import styles from './RegistrationStep2.module.css'
import {CountrySelector} from "react-international-phone";
import 'react-international-phone/style.css';
import InputMask from "react-input-mask";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import {DeviceUUID} from 'device-uuid'
import * as VKID from '@vkid/sdk';

/*
VKID.Config.init({
    app: 52225543, // Идентификатор приложения.
    redirectUrl: 'http://localhost', // Адрес для перехода после авторизации.
    state: 'dj29fnsadjsd82', // Произвольная строка состояния приложения.
    codeVerifier: 'FGH767Gd65', // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
    scope: 'email phone', // Список прав доступа, которые нужны приложению.
    mode: VKID.ConfigAuthMode.Redirect // По умолчанию авторизация открывается в новой вкладке.
});
*/


const RegistrationStep2 = (props) => {

    let needGo = false;

    const config = require('../../../settings/config.js');

    const location = useLocation();

    const navigate = useNavigate();

    const [phone, setPhone] = useState(() => {
        return localStorage.getItem("phone") ? localStorage.getItem("phone") : ''
    });
    const [country, setCountry] = useState({
        name: 'Russia',
        iso2: 'ru',
        dialCode: '7',
        format: '(___) ___-__-__',
        priority: undefined
    });
    const [phoneMask, setPhoneMask] = useState('+7 (999) 999-99-99'); // {name: 'Goland', iso2: 'pl', dialCode: '48', format: '...-...-...', priority: undefined, …}
    const [placeholder, setPlaceHolder] = useState('+7 ');
    const [isCanChangeKeys, setCanChangeKeys] = useState(false);

    const inputRef = useRef(null);

    function handleSelectCountry(e) {
        setCountry(e);
        let phoneMask = '';
        if (e.format) {
            let reFormat = e.format.replace(/\./g, "9");
            phoneMask = '+' + e.dialCode + ' ' + reFormat;
        } else {
            phoneMask = '+\\' + e.dialCode;
        }
        setPhoneMask(phoneMask);
        /*setPhoneMask(e.format ? '+' + e.dialCode + ' ' + e.format : '+' + e.dialCode);qq*/
        setPlaceHolder('+' + e.dialCode);
        setPhone('');

        if (inputRef.current) {
            inputRef.current.focused = true;
            inputRef.current.setCursorToEnd();
            inputRef.current.getInputDOMNode().select();
        }
    }

    function handleInputPhone(e) {
        setPhone(e.target.value);
    }

    async function handleGetSMSButton(e) {
        let uuid = new DeviceUUID().get();
        let resp = '';

        if (phone.length === 18) {
            localStorage.setItem('phone', phone);

            // Request SMS code+fix cors
            try {
                const response = await fetch(`${config.app.host}/api/v1/getotp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({username: phone.replace(/\D/g, ''), deviceId: uuid,}),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();

            } catch (error) {
                toast.error('Что-то пошло не так', {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }
            // End request SMS code
            toast.success(`Вам отправлен SMS-код: ${resp.token}`, {
                position: "top-center",
                autoClose: 10000
            });
            navigate('/step3');
        }
    }

    async function handleLoginOnVK() {
        try {
            fetch(`${config.app.host}/api/v1/vk/pkce-params`, {
                mode: 'cors',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                /*body: JSON.stringify({
                    username: user,
                    password: pass,
                })*/
            })
                .then(response => response.json())
                .then(responseJson => {
                    console.log(responseJson);
                    VKID.Config.init({
                        app: config.vk.clientId, // Идентификатор приложения.
                        redirectUrl: config.vk.redirectUrl, // Адрес для перехода после авторизации.
                        state: responseJson.state, // Произвольная строка состояния приложения.
                        codeVerifier: responseJson.codeChallenge, // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
                        scope: 'email phone', // Список прав доступа, которые нужны приложению.
                        mode: VKID.ConfigAuthMode.InNewTab // По умолчанию авторизация открывается в новой вкладке.
                    });
                    VKID.Auth.login();
                });

        } catch (error) {
            console.log(error);
        } finally {
            setCanChangeKeys(true);
        }
    }

    useEffect(() => {
        const query = window.location.search;
        console.log("Query from window.location.search:", query);

        const params = new URLSearchParams(query);
        const code = params.get('code');
        const deviceId = params.get('device_id');
        const state = params.get('state');
        const type = params.get('type');

        console.log('Code:', code);
        console.log('Device ID:', deviceId);
        console.log('State:', state);
        console.log('Type:', type);

        // отправляем запрос на бэк для обмена ключами


        async function getVKToken() {
            // Request exchange code to VK
            try {
                const response = await fetch(`${config.app.host}/api/v1/vk/token`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            code: code,
                            deviceId: deviceId,
                            state: state,
                            type: type,
                        }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                let resp = await response.json();
                console.log("at" + resp.access_token);
                console.log("rt" + resp.refresh_token);
                console.log("idt" + resp.id_token);
                console.log("type" + resp.token_type);
                console.log("exp" + resp.expires_in);
                console.log("u_id" + resp.user_id);
                console.log("state" + resp.state);
                console.log("scope" + resp.scope);

                if (resp.access_token)
                    localStorage.setItem('vk_access_token', resp.access_token);
                if (resp.user_id)
                    localStorage.setItem('vk_user_id', resp.user_id);
                if (resp.state)
                    localStorage.setItem('vk_state', resp.state);
                if (resp.state)
                    localStorage.setItem('vk_client_id', resp.client_id);

            } catch (error) {
                toast.error('Что-то пошло не так in vk/token', {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }

            toast.success(`коды обменяны`, {
                position: "top-center",
                autoClose: 10000
            });
            // End request exchange code to VK
        }

        if (code && state && deviceId && type) {
            getVKToken();
        }
    }, [isCanChangeKeys]);

    async function handleLogoutVK() {
        let accessToken = localStorage.getItem('vk_access_token');
        let resp = '';

        if (accessToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/vk/logout`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            access_token: accessToken,
                        }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    toast.error('Ошибка:' + resp.error, {
                        position: "top-center",
                        autoClose: 3000
                    });
                    return;
                }

            } catch (error) {
                toast.error('Что-то пошло не так in vk/logout', {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }
            // End request SMS code
            toast.success(`Logout successfuly!`, {
                position: "top-center",
                autoClose: 3000
            });
        }


        /*try {
            const resp = fetch(`https://id.vk.com/oauth2/logout`, {
                mode: 'no-cors',
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: JSON.stringify({
                    access_token: accessToken,
                    client_id: 52225543
                })
            })
            let result = await resp.json();
            /!*.then(response => response.json())
            .then(responseJson => {
                console.log(responseJson);
                toast.success(`Logout successfuly!`, {
                    position: "top-center",
                    autoClose: 3000
                });
            });*!/

        } catch (error) {
            console.log(error);
            toast.error('Что-то пошло не так in vk/logout', {
                position: "top-center",
                autoClose: 3000
            });
        } finally {
        }*/


    }


    return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.titleText}>Зарегистрируйтесь <br/> или войдите в PSS Charge</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block*/}

                {/*start row block -- "input your phone number text" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>Введите номер телефона</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block*/}

                {/*// ToDo: После выбора страны, фокус ввода перемещать на поле ввода номера телефона, в конец строки (позицию курсора, чтобы можно было сразу набирать номер*/}
                {/*start row block -- input field for phone number */}
                <div className={styles.row1}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.phoneContainer}>
                        <div className={styles.selectCountryFlagDiv}>
                            <CountrySelector
                                selectedCountry={country.iso2}
                                value={country}
                                onSelect={handleSelectCountry}
                            />
                        </div>
                        <div className={styles.inputPhoneDiv}>
                            <InputMask
                                type="tel"
                                mask={phoneMask}
                                placeholder={placeholder}
                                maskChar={null}
                                value={phone}
                                onChange={handleInputPhone}
                                className={phone.length !== 18 ? `${styles.input}` : `${styles.inputSuccess}`}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r2c3*/}</div>
                {/*end row block*/}

                {/*start row block -- get SMS button*/}
                <div className={styles.row1}>{/*r3c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.getSmsButton}>
                        <button
                            className={phone.length !== 18 ? `${styles.smsButton}` : `${styles.smsButtonActive}`}
                            /*className={styles.smsButton}*/
                            onClick={handleGetSMSButton}
                        >Получить СМС-код
                        </button>
                    </div>
                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row1}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.or}>
                        {/*"OR-IMAGE" on background*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row1}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    <div className={`${styles.appleButton} ${styles.buttons}`}>
                        <button onClick={handleLoginOnVK}>Войти через VK ID</button>
                        &nbsp;&nbsp;
                        <button onClick={handleLoginOnVK}>Get VK User info</button>
                        &nbsp;&nbsp;
                        <button onClick={handleLogoutVK}>Logout VK ID</button>
                        {/*"continue with Apple Button" on background*/}
                    </div>

                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row1}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    <div className={`${styles.googleButton} ${styles.buttons}`}>
                        {/*"continue with Google Button" on background*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    <div className={`${styles.emailButton} ${styles.buttons}`}>
                        {/*"continue with Email Button" on background*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        <div className={styles.privacyText}>
                            Продолжая, вы соглашаетесь <u>с политикой <br/>
                            конфиденциальности</u> приложения и <u>правилами <br/>
                            компании</u>
                            {/* <div className={styles.container}>
                                <p>code: {searchParams.get("code")}</p><br/>
                                <p>expires_in: {searchParams.get("expires_in")}</p><br/>
                                <p>device_id: {searchParams.get("device_id")}</p><br/>
                                <p>state: {searchParams.get("state")}</p><br/>
                                <p>type: {searchParams.get("type")}</p><br/>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
            </div>
        );
    }
;

export default RegistrationStep2;
