import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeScreen.module.css'
import {PinInput} from 'react-input-pin-code';
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';

const PinCodeScreen = () => {

    const [values, setValues] = React.useState(['', '', '', '']);
    const navigate = useNavigate();

    function handlePinInput(value, index, values) {
        setValues(values);
    }

    function handlePinComplite(values) {
        const storagePin = localStorage.getItem('pin');
        if (values.join('') === storagePin) {
            // TODO ВНИМАНИЕё ТУДУ ТУУУУ ДУУУУУ!!!! Если пин правильный, сходить на бэк(мобильный) за свежим токеном скунса
            navigate('/step5');
        } else {
            toast.error('Неверный PIN-код', {
                position: "top-center",
                autoClose: 3000
            });
            values.fill('');
            setValues(values);
            navigate('/');
            return;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.pinContainer}>
                <div className={styles.pinText}> Введите PIN-код:</div>
                <PinInput
                    values={values}
                    onChange={handlePinInput}
                    onComplete={handlePinComplite}
                    autoFocus
                />
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.button}
                        onClick={() => {
                            localStorage.removeItem('pin');
                            navigate('/');
                        }}
                >Сбросить pin-код
                </button>
            </div>
        </div>
    );
}

export default PinCodeScreen;