import React, {useEffect, useState} from 'react';
import styles from './RegistrationStep6.module.css'
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


const RegistrationStep6 = (props) => {

        const config = require('../../../settings/config.js');
        const navigate = useNavigate();
        const [data, setData] = useState([]);
        const token = localStorage.getItem('s_token');
        const groupId = JSON.parse(localStorage.getItem('groupId'));

        useEffect(() => {
            const fetchData = async () => {
                try {
                    /*const response = await fetch(`http://94.228.117.74:10023/pss/api/v1/table/get?groupId=${token.groupId}`, {*/
                    const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/table/get?groupId=q`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        toast.error('Response not ok ' + response.status, {
                            position: "top-center",
                            autoClose: 3000
                        });
                    }

                    const result = await response.json();
                    setData(result.content);
                    console.log(data);
                } catch (error) {
                    toast.error('Response not ok ' + error, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
            };

            fetchData();
        }, []);


        const handleDetail = (item) => {
            navigate('/step7', {state: {item}});
        };

        return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.roundButtons}>
                        <div className={styles.buttonsContainer}>
                            <div className={styles.buttonsContainerLeft}>
                                <div>
                                    <button className={styles.btRect}></button>
                                </div>
                                <div>
                                    <button className={styles.btMic}></button>
                                </div>
                            </div>
                            <div className={styles.buttonsContainerRight}>
                                <div>
                                    <button className={styles.btCoin}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "Page title"*/}

                {/*start row block -- "An SMS will be sent to your number" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>
                        <div className={styles.buttonsContainerBonus}>
                            <div>
                                Мои станции:
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "An SMS will be sent to your number"*/}

                {/*start row block - "Phone number and change it"*/}
                <div className={styles.row2}>{/*r2c1*/}</div>
                <div className={styles.column2}>


                    {data.map((item) => (
                        <div key={item.id} onClick={() => handleDetail(item)} className={styles.item}>
                            <h3>{item.nickName}</h3>
                            <p>Model: {item.model}</p>
                            <p>Charge Point ID: {item.chargePointId}</p>
                            <p>Power: {item.powerCp} kW</p>
                        </div>
                    ))}


                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row4}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.buttonsContainerCenter}>
                        <div>
                            ...
                        </div>
                        <div>
                            ....
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row5}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                    <div>
                        .....
                    </div>

                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row6}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        {/*new line*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
            </div>
        );
    }
;

export default RegistrationStep6;
