import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import styles from "../Step6/RegistrationStep6.module.css";
import {Button, Modal} from "react-bootstrap";

const RegistrationStep5 = (props) => {

    const config = require('../../../settings/config.js');

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const token = localStorage.getItem('s_token');

    const [isOpen, setIsOpen] = useState(false); // для кнопки "технические подробности"
    const [statResult, setStatResult] = useState([]);
    const [show, setShow] = useState(false); // для модального окна
    const toggle = () => setIsOpen(!isOpen);
    // Функции для управления модальным окном
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                /*const response = await fetch(`http://94.228.117.74:10023/pss/api/v1/table/get?groupId=${token.groupId}`, {*/
                const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/table/get?groupId=q`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    toast.error('Response not ok ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }

                const result = await response.json();
                setData(result.content);
                console.log(data);
            } catch (error) {
                toast.error('Response not ok ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };

        fetchData();
    }, []);

    const handleDetail = (chargeStation) => {
        navigate('/step55', {state: {chargeStation}});
    };

    async function getStatistics(sn) {
        try {
            const response = await fetch(`https://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/local-stat-tab/all-transactions?sn=${sn}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setStatResult(result);
            handleShow(); // модальное окно
            console.log(statResult);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container mt-4">
            {/* Модальное окно  для статистики*/}
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Статистика транзакций</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Проверяем, есть ли данные */}
                    {statResult.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                {/*<th>ID</th>*/}
                                {/*<th>Тип</th>*/}
                                {/*<th>ID Тэг</th>*/}
                                <th>Энергия (кВтч)</th>
                                <th>Начало</th>
                                <th>Конец</th>
                                <th>Ошибка</th>
                                {/*<th>Причина остановки</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {/* Перебираем данные и отображаем в таблице */}
                            {statResult.map((stat) => (
                                <tr key={stat.id}>
                                    {/*<td>{stat.id}</td>*/}
                                    {/* <td>{stat.type}</td>*/}
                                    {/*<td>{stat.idTag}</td>*/}
                                    <td>{stat.consumeEnergy}</td>
                                    <td>{new Date(stat.dataStartTransaction).toLocaleString()}</td>
                                    <td>{new Date(stat.dataEndTransaction).toLocaleString()}</td>
                                    <td>{stat.stopError}</td>
                                    {/*<td>{stat.reasonStop || 'Не указана'}</td>*/}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Нет данных для отображения</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Заголовок */}
            <h2 className="text-center mb-4">Мои зарядные станции</h2>
            <p className="text-center text-muted mb-5">Нажмите на станцию для управления</p>

            {/* Карточка с доступными станциями */}
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Доступные зарядные станции</h5>
                    <div className="text-center">
                        {data.map((item) => (
                            <div key={item.id} onClick={() => handleDetail(item)} className={styles.item}>
                                <h3>{item.nickName}</h3>
                                <p>{item.address}</p>
                                <p>SN: {item.sn}</p>
                                <p>Power: {item.powerCp} kW</p>
                                <div className={styles.buttonsContainerCenter}>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Предотвращает срабатывание обработчика клика для родительского div
                                            getStatistics(item.sn);
                                        }}
                                        className={`btn btn-info`}
                                    >
                                        История зарядок
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationStep5;
